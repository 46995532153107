<template>
  <v-app>
    <div id="app">
      <HeaderVue v-if="showHeader" />
      <router-view />
      <AppLoaderVue />
    </div>
  </v-app>
</template>

<script>
import HeaderVue from "@/components/HeaderVue";
import AppLoaderVue from "@/components/AppLoaderVue";
import { getAuth } from "firebase/auth";
import { onLogout } from "./vue-apollo";
import store from "./storages/index";

export default {
  name: "main-component",
  data() {
    return {
      auth: getAuth(),
    };
  },
  components: {
    HeaderVue,
    AppLoaderVue,
  },

  computed: {
    showHeader() {
      return this.$route.name !== "login";
    },
  },
  methods: {
    checkedTime() {
      const tokenTime = 3600000;
      let now = new Date().getTime();
      let lastLogin = this.auth.currentUser.metadata.lastLoginAt;
      let time = lastLogin - now;
      let newTime = new Date(time);
      if (newTime >= tokenTime) {
        return false;
      } else {
        return true;
      }
    },
  },

  created() {
    if (localStorage.getItem("access-token")) {
      store.dispatch("setIsGraphToken", true);
    }

    store.dispatch("SET_VALUE", { key: "initAppLoginLoading", value: true });
    this.auth.onAuthStateChanged((user) => {
      if (user && this.checkedTime()) {
        this.$router.push("/").catch(() => {});
        store.dispatch("SET_VALUE", {
          key: "initAppLoginLoading",
          value: false,
        });
      } else {
        store.dispatch("SET_VALUE", {
          key: "initAppLoginLoading",
          value: false,
        });
        // console.log("Время вышло");
        this.auth.signOut();
        onLogout(this.$apollo);
        this.$router.push("/login").catch(() => {});
      }
    });
  },

  watch: {
    $route(to) {
      // ленивая подгрузка иконок материала
      if (to.name !== "login") {
        // иконки не нужны на странице логина, так что подгружаем когда оказываемся на любой странице кроме логина
        let cssIcon = "cssIcon";
        // let cssBootstrap = 'cssBootstrap'
        // if (!document.getElementById(cssBootstrap)) {
        //   let head  = document.getElementsByTagName('head')[0]
        //   let link  = document.createElement('link')
        //   link.id   = cssBootstrap
        //   link.rel  = 'stylesheet'
        //   link.type = 'text/css'
        //   link.href = 'https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css'
        //   link.media = 'all'
        //   head.appendChild(link)
        // }
        if (!document.getElementById(cssIcon)) {
          let head = document.getElementsByTagName("head")[0];
          let link = document.createElement("link");
          link.id = cssIcon;
          link.rel = "stylesheet";
          link.type = "text/css";
          link.href =
            "https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css";
          link.media = "all";
          head.appendChild(link);
        }
      }
    },
  },
};
</script>


<style lang="scss">
.icon {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &-svg {
    width: 13%;
    height: 13%;
  }
}
#app {
  // max-height: 100vh;
  height: 100%;
}
a:hover {
  text-decoration: none;
}

#nav {
  padding: 30px;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.pointer {
  cursor: pointer;
}
.img-load {
  width: 200px;
  .path {
    fill: none;
    stroke-dasharray: 1000;
    stroke-dashoffset: 550;
    animation: dash 5s ease-in alternate infinite;
  }

  @keyframes dash {
    from {
      stroke-dashoffset: 522;
      stroke-dasharray: 500;
    }
    to {
      stroke-dashoffset: 0;
      stroke-dasharray: 2000;
    }
  }
}
.vdialognew {
  box-shadow: none;
}
.map {
  // используется в publicMapsVue и ProjectMapsVue
  // padding: 60px;
  padding-top: auto;
  &__subheader {
    height: 19px;
  }
  &__subheader-link {
    position: relative;
    bottom: 15px;
    span {
      font-size: 12px;
      color: gray;

      img {
        padding-left: 5px;
        padding-bottom: 4px;
        height: 16px;
      }
    }
  }
  &__item {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    border-radius: 3px;
    overflow: hidden;
    transition: 0.3s;
    p {
      font-size: 18px;
      font-weight: bold;
      position: relative;
      z-index: 3;
    }
    &:hover {
      box-shadow: 0px 0px 11px 4px rgba(34, 60, 80, 0.2);
      .map__menu {
        bottom: 0;
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &__item-no-image {
    position: absolute;
    z-index: 1;
    border-radius: 3px;
    background: linear-gradient(90deg, rgb(255, 113, 103) 0%, #ffa726);
    // opacity: 0.5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &__item-image {
    z-index: 2;
    position: absolute;
    border-radius: 3px;
    // opacity: 0.5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50% 50%;
    &:after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
  &__menu {
    position: absolute;
    display: flex;
    justify-content: space-between;
    bottom: -45px;
    z-index: 1;
    left: 0;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
  }
  &__btn {
    flex-basis: 50%;
  }
}
</style>
