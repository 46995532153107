import Vue from 'vue'
import VueRouter from 'vue-router'
const HomeVue = () => import('@/components/HomeVue.vue')
const LoginVue = () => import('@/components/login/LoginVue.vue')
const PublicMapsVue = () => import('@/components/publicMaps/PublicMapsVue')
const ProjectMainVue = () => import('@/components/projectMaps/ProjectMainVue')
const FaqVue = () => import('@/components/FaqVue.vue')
// import { authGuard } from '../auth/authGuard'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeVue,
    // beforeEnter: authGuard
  },
  {
    path: '/project/:prjCodename',
    name: 'project-maps',
    component: ProjectMainVue,
    // beforeEnter: authGuard,
    props: true
  },
  {
    path: '/login',
    name: 'login',
    component: LoginVue
  },
  {
    path: '/public-maps',
    name: 'public-maps',
    component: PublicMapsVue
    // beforeEnter: () => {
    //   console.log('beforeRouteEnter')
    //   localStorage.removeItem('useToken')
    // },
    // beforeLeave: () => {
    //   console.log('beforeRouteLeave')
    //   localStorage.setItem('useToken', true)
    // }
  },
  {
    path: '/faq',
    name: 'faq',
    component: FaqVue
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name === 'public-maps') { // если публичные карты, то убираем заголовок авторизации(смотри vue-apollo.js)
    // console.log('del')
    localStorage.removeItem('useToken')
  } else { // иначе используем заголовок авторизации
    // console.log('add')
    localStorage.setItem('useToken', true)
  }
  next()
})

export default router
