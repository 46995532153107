export default {
  data () {
    return {
      prjNameRulesMix: [
        value => !!value || 'Значение не должно быть пустым',
        value => (value || '').length <= 30 || 'Не более 30 символов',
        value => {
          const pattern = new RegExp(/^[a-zA-Zа-яА-Я].+/mg)
          return pattern.test(value) || 'Название должно начинаться с буквы'
        },
        value => {
          let noSymbolArr = new RegExp(/[[\]!@^`|&/\\#,+()$~%.'":*?<>{}=]/gm)
          return !noSymbolArr.test(value) || 'Название не должно содержать спец. симолы кроме - и _'
        }
      ]
    }
  },

  methods: {
    /**
     * Обертка над Promise.all, которая возвращает все результаты(успешные и нет)
     * @param {Array} arr 
     * @returns {Array} - массив разрешенных промисов вида
     * {
     *   state: 'fullfilled/rejected',
     *   value: значение / ошибка
     * }
     */

    settleMix (arr) {
      return Promise.all(arr.map(promise => {
        return promise.then(
          value => ({state: 'fullfilled', value}),
          value => ({state: 'rejected', value})
        )
      }))
    }    
  }
}