<template>
  <v-dialog
    persistent
    content-class="vdialognew"
    :overlay-opacity="1"
    v-model="loadingApp"
    width="200"
  >
    <div class="img-load">
      <!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        style="enable-background: new 0 0 512 512"
        xml:space="preserve"
      >
        <g>
          <g>
            <path
              class="path"
              stroke="#ffa726"
              stroke-width="10"
              d="M387.664,464.017c-2.77-4.774-8.887-6.4-13.667-3.63l-0.385,0.222c-4.776,2.771-6.401,8.89-3.631,13.667
            c1.855,3.197,5.21,4.983,8.658,4.983c1.702,0,3.43-0.437,5.009-1.353l0.385-0.222
            C388.809,474.913,390.434,468.794,387.664,464.017z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="path"
              stroke="#ffa726"
              stroke-width="10"
              d="M477.694,128.08C443.512,68.875,388.321,26.526,322.287,8.832C256.255-8.862,187.283,0.219,128.078,34.4
            C68.874,68.581,26.524,123.773,8.831,189.806c-17.693,66.033-8.613,135.006,25.568,194.21
            c30.661,53.107,79.393,93.468,137.219,113.646c27.411,9.564,56.026,14.337,84.597,14.337c31.065,0,62.074-5.646,91.385-16.911
            c5.155-1.981,7.727-7.766,5.745-12.921s-7.768-7.73-12.921-5.745c-78.02,29.987-162.482,16.362-225.585-31.004l8.959-46.765
            l4.317-1.402c16.313-5.3,25.273-22.884,19.975-39.198l-3.168-9.749c-0.733-2.256-0.729-4.641,0.01-6.895
            c2.779-8.472,1.786-17.741-2.725-25.433c-4.511-7.691-12.116-13.084-20.867-14.795l-22.57-4.41l-43.548-33.11
            c-2.609-1.984-6.024-2.563-9.142-1.551l-25.496,8.282c-0.541-9.119-0.549-18.235-0.035-27.303
            c1.006,0.937,1.661,1.574,2.064,1.992c0.499,0.831,1.117,1.593,1.841,2.253c2.082,1.896,3.968,2.756,7.928,2.756
            c3.639-0.001,9.026-0.726,17.921-2.04c8.436-1.247,16.828-2.652,16.911-2.666c3.363-0.563,6.206-2.803,7.544-5.938
            c1.338-3.136,0.985-6.737-0.936-9.555l-12.261-17.984l17.562-12.558c1.17-0.837,2.146-1.916,2.861-3.164l27.223-47.525
            l10.973-11.758c11.213-12.018,14.413-29.601,8.151-44.797c-3.694-8.963-10.146-16.425-18.216-21.454
            c8.74-6.871,18.059-13.215,27.963-18.932c36.338-20.98,75.994-31.147,115.233-31.536l-17.856,22.399L169.24,65.795
            c-2.689,0.942-4.849,2.988-5.935,5.622l-18.492,44.828c-1.313,3.185-0.896,6.819,1.105,9.623l27.008,37.832
            c-3.868,3.529-7.633,8.669-12.13,15.051c-1.972,2.799-3.834,5.442-5.121,6.924c-1.119,1.29-2.239,2.55-3.349,3.798
            c-5.809,6.535-11.815,13.294-16.082,22.322c-9.382,19.859-7.487,42.941,4.945,60.239c12.021,16.725,31.839,25.227,52.991,22.739
            c3.446-0.404,6.659-1.199,9.768-1.969c8.573-2.121,11.289-2.333,14.039,0.241c1.338,1.253,1.461,1.49,1.434,5.739
            c-0.019,2.704-0.04,6.068,0.892,9.909c1.493,6.145,5.352,10.392,8.452,13.804c1.541,1.695,3.135,3.449,3.626,4.626
            c3.119,7.474,1.761,11.613-1.215,20.683c-0.255,0.776-0.515,1.568-0.776,2.38c-4.521,13.995,1.781,27.877,7.341,40.123
            c1.806,3.977,3.511,7.731,4.628,11.049c8.991,26.679,15.731,32.789,21.14,35.249c2.833,1.288,5.765,1.853,8.727,1.852
            c14.415-0.001,29.457-13.385,36.367-21.87c4.34-5.328,5.409-10.663,6.189-14.56c0.389-1.938,0.695-3.468,1.279-4.468
            c0.894-1.53,1.947-2.716,3.28-4.218c2.681-3.019,6.018-6.775,8.179-13.584c1.642-5.172,2.835-6.44,6.786-10.648
            c0.688-0.731,1.421-1.513,2.208-2.369c13.319-14.493,9.889-25.564,6.256-37.286c-3.057-9.863,2.29-16.171,15.218-28.21
            c5.604-5.219,11.398-10.615,15.877-17.099c1.96-2.838,7.923-11.471,4.233-20.028c-3.617-8.39-12.81-9.8-20.196-10.932
            c-2.917-0.448-7.325-1.124-8.728-2.041c-6.187-4.045-9.972-12.487-13.633-20.652c-0.729-1.625-1.448-3.229-2.177-4.786
            c-1.3-2.778-2.599-6.139-3.974-9.697c-3.638-9.416-7.761-20.089-14.78-27.529c-6.309-6.685-18.202-9.905-28.695-12.747
            c-3.529-0.955-6.86-1.857-9.057-2.668c-1.841-0.68-3.839-0.803-5.745-0.355c-5.107,1.197-8.293,2.516-10.659,4.412
            c-0.625,0.501-2.476,2.146-3.56,4.79c-3.661-1.757-8.695-5.112-11.793-7.177c-0.064-0.043-0.129-0.086-0.193-0.129
            c1.044-4.178,0.099-7.531-0.891-9.625c-5.586-11.816-24.129-10.891-27.787-10.58c-2.078,0.174-4.707,0.267-7.488,0.365
            c-4.368,0.155-9.196,0.329-13.973,0.865l0.246-0.686c3.626-10.103,13.274-16.891,24.008-16.891h5.685
            c5.521,0,9.999-4.478,9.999-9.999c0-5.521-4.478-9.999-9.999-9.999h-5.685c-15.936,0-30.529,8.387-38.708,21.588l-14.915-20.893
            l14.674-35.569L244.787,60.5c1.774-0.622,3.338-1.732,4.511-3.203l28.763-36.079c73.494,6.991,142.674,48.197,182.316,116.859
            c10.559,18.289,18.37,37.543,23.563,57.236l-4.239,3.78c-5.533,4.937-8.775,12.018-8.899,19.429
            c-0.001,0.081-0.003,0.161-0.006,0.241l-6.906-18.992c-1.029-2.834-2.545-5.433-4.503-7.724l-12.319-14.423
            c-4.776-5.591-11.73-8.796-19.081-8.796h-15.079c-5.353,0-10.245,2.932-12.77,7.649c-2.525,4.718-2.249,10.416,0.72,14.868
            l1.526,2.291c-9.744,8.379-21.014,15.084-33.014,19.606L348.7,173.649v-8.833c0-2.732-1.118-5.347-3.095-7.232l-18.613-17.769
            c-1.027-0.98-2.251-1.73-3.59-2.201l-16.755-5.888c-5.213-1.834-10.919,0.909-12.749,6.118
            c-1.831,5.211,0.908,10.918,6.118,12.749l14.724,5.175l13.962,13.327v7.008c0,1.611,0.39,3.199,1.136,4.628l25.877,49.567
            c2.327,4.46,7.609,6.498,12.323,4.754l7.535-2.778c17.505-6.456,33.73-16.841,46.922-30.031c3.37-3.371,3.894-8.65,1.249-12.617
            l-0.532-0.799h4.775c1.493,0,2.906,0.651,3.876,1.787l12.319,14.423c0.397,0.465,0.704,0.991,0.914,1.568l13.595,37.383
            c1.168,3.212,3.896,5.604,7.23,6.344c0.72,0.159,1.445,0.237,2.166,0.237c2.623,0,5.176-1.033,7.07-2.929l6.471-6.471
            c4.098-4.099,6.919-9.285,8.267-15.034c10.008,80.205-21.441,163.173-87.915,215.347c-4.345,3.409-5.103,9.694-1.692,14.039
            c3.409,4.345,9.693,5.103,14.039,1.692c47.992-37.667,80.962-91.542,92.837-151.701
            C519.15,244.773,508.685,181.758,477.694,128.08z M47.282,282.747l41.233,31.351c1.219,0.927,2.633,1.561,4.135,1.854
            l24.852,4.856c3.172,0.62,5.818,2.496,7.453,5.284c1.635,2.787,1.979,6.012,0.973,9.083c-2.07,6.313-2.08,12.99-0.027,19.307
            l3.168,9.748c1.893,5.826-1.309,12.107-7.134,14l-9.937,3.229c-3.467,1.126-6.045,4.05-6.73,7.628l-7.998,41.749
            c-17.583-16.093-33.021-35.113-45.552-56.818C36.43,347.541,26.878,319.3,22.668,290.744L47.282,282.747z M105.559,123.258
            l-11.763,12.603c-0.525,0.563-0.984,1.185-1.367,1.853L65.717,184.35l-23.741,16.976c-4.422,3.162-5.508,9.275-2.446,13.767
            l8.879,13.024c-5.118,0.762-9.711,1.39-12.765,1.723c-0.587-0.563-1.201-1.134-1.836-1.724c-2.377-2.208-5.68-5.157-10.604-9.466
            c8.121-50.263,32.341-97.615,70.433-133.76c7.294,2.297,13.28,7.738,16.204,14.835
            C113.131,107.707,111.449,116.945,105.559,123.258z M187.089,177.901c4.46-2.51,14.812-2.877,22.37-3.145
            c3.029-0.107,5.891-0.209,8.447-0.423c1.349-0.113,2.847-0.092,4.26,0.018c-0.631,1.62-0.836,3.398-0.562,5.162
            c0.481,3.103,2.394,5.798,5.162,7.279c1.876,1.004,4.487,2.745,7.251,4.588c7.815,5.21,16.674,11.115,24.996,11.645
            c7.568,0.491,11.779-3.822,13.583-5.659c0.144-0.146,0.28-0.292,0.424-0.425c1.016-0.828,1.968-1.938,2.676-3.277
            c1.599,0.457,3.279,0.912,5.001,1.379c5.921,1.603,16.942,4.587,19.377,7.167c4.325,4.585,7.696,13.312,10.671,21.011
            c1.503,3.891,2.923,7.563,4.516,10.968c0.684,1.461,1.357,2.966,2.041,4.49c4.614,10.292,9.844,21.958,20.939,29.21
            c5.031,3.289,11.198,4.234,16.64,5.069c0.658,0.101,1.412,0.217,2.178,0.343c-3.23,4.506-7.829,8.788-12.688,13.313
            c-12.379,11.528-27.785,25.876-20.69,48.765c3.349,10.808,3.505,11.976-1.88,17.834c-0.734,0.799-1.418,1.527-2.059,2.209
            c-4.612,4.91-8.255,8.788-11.271,18.288c-0.876,2.759-2.011,4.037-4.072,6.357c-1.648,1.856-3.701,4.168-5.594,7.408
            c-2.268,3.882-3.018,7.62-3.619,10.623c-0.593,2.955-0.929,4.438-2.089,5.862c-2.641,3.242-7.567,7.95-12.624,11.163
            c-4.7,2.984-7.444,3.392-8.379,3.336c-1.067-1.144-4.932-6.153-10.772-23.486c-1.44-4.276-3.438-8.676-5.37-12.931
            c-4.259-9.38-8.662-19.08-6.52-25.709c0.253-0.782,0.503-1.546,0.749-2.293c3.422-10.43,6.652-20.279,0.668-34.619
            c-1.822-4.367-4.723-7.558-7.28-10.374c-1.655-1.821-3.53-3.885-3.819-5.073c-0.352-1.449-0.341-3.122-0.328-5.059
            c0.034-5.225,0.086-13.12-7.763-20.47c-11.066-10.358-23.97-7.167-32.51-5.055c-2.623,0.648-5.101,1.262-7.297,1.52
            c-14.048,1.646-26.59-3.654-34.42-14.55c-8.191-11.397-9.38-26.734-3.102-40.024c3.039-6.431,7.63-11.598,12.946-17.578
            c1.161-1.307,2.333-2.625,3.505-3.975c1.95-2.248,4.096-5.292,6.365-8.515C179.855,186.423,184.899,179.263,187.089,177.901z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="path"
              stroke="#ffa726"
              stroke-width="10"
              d="M410.022,344.278l-4.811-18.134c-1.051-3.96-4.411-6.878-8.478-7.364c-4.072-0.476-8.021,1.56-9.976,5.161
            c-1.096,2.02-2.083,4.16-3.037,6.229c-1.605,3.481-3.265,7.081-4.992,9.068c-0.97,1.116-3.462,2.319-5.87,3.481
            c-5.757,2.778-13.642,6.583-16.654,16.185c-1.87,5.956-1.172,10.668-0.662,14.108c0.441,2.983,0.543,4.017-0.256,5.893l-0.1,0.233
            c-3.183,7.469-9.105,21.374-0.801,33.195c5.925,8.436,12.155,10.605,16.996,10.605c0.945,0.003,1.839-0.08,2.666-0.215
            c10.2-1.667,17.868-12.277,24.134-33.393l11.762-39.641C410.466,347.926,410.494,346.054,410.022,344.278z M379.01,383.641
            c-3.194,10.766-6.02,15.792-7.651,18.02c-0.188-0.24-0.392-0.512-0.61-0.823c-1.911-2.722,0.945-9.426,2.835-13.863l0.101-0.234
            c3.048-7.154,2.233-12.648,1.64-16.66c-0.381-2.569-0.517-3.673-0.04-5.19c0.392-1.247,2.735-2.457,6.265-4.161
            c1.565-0.756,3.288-1.587,5.027-2.593L379.01,383.641z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="path"
              stroke="#ffa726"
              stroke-width="10"
              d="M270.03,118.86l-0.675-0.236c-5.207-1.824-10.917,0.915-12.745,6.126c-1.828,5.212,0.915,10.917,6.126,12.745l0.674,0.236
            c1.095,0.384,2.211,0.566,3.31,0.566c4.128,0,7.992-2.577,9.436-6.692C277.984,126.393,275.241,120.687,270.03,118.86z"
            />
          </g>
        </g>
      </svg>
    </div>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      loadingApp: true,
    };
  },

  computed: {
    initAppLoginLoading() {
      return this.$store.getters.VALUE("initAppLoginLoading");
    },
  },

  watch: {
    initAppLoginLoading(val) {
      // console.log('watch afldfaskl', val)
      this.loadingApp = val;
    },
  },
};
</script>

<style>
</style>