export default {
  state: {
    isGraphToken: false,
    initAppLoginLoading: true
  },

  mutations: {
    SET_VALUE (state, keyVal) {
      state[keyVal.key] = keyVal.value
    },

    setIsGraphToken (state, val) {
      state.isGraphToken = val
    }
  },

  actions: {
    SET_VALUE ({commit}, keyVal) {
      commit('SET_VALUE', keyVal)
    },

    setIsGraphToken ({commit}, val) {
      // console.log('setIsGraphToken', val)
      commit('setIsGraphToken', val)
    }
  },

  getters: {
    VALUE: state => key => {
      return state[key]
    },

    IS_GRAPH_TOKEN: state => {
      return state.isGraphToken
    }
  }
}