<template>
  <div class="header">
    <v-container fluid class="px-sm-5 px-md-7 px-lg-10">
      <v-row align-content="space-between">
        <v-col cols="3" md="1">
          <!-- <div class="header__row"> -->
          <router-link to="/">
            <img class="logo" src="@/assets/img/logo.png" alt="" />
          </router-link>
        </v-col>

        <v-col align-self="center">
          <v-tabs
            class="d-none d-md-block"
            right
            color="orange"
            optional
            :show-arrows="true"
            :hide-slider="hideSlider"
          >
            <v-tabs-slider color="orange"></v-tabs-slider>
            <!-- <v-tab :to="{name: 'home'}">
              Мои проекты
            </v-tab> -->
            <v-tab :to="{ name: 'public-maps' }"> Публичные карты </v-tab>
            <v-tab :to="{ name: 'faq' }"> FAQ </v-tab>
          </v-tabs>
        </v-col>

        <v-col align-self="center" class="text-end" cols="3" md="1" lg="1">
          <!-- show login when not authenticated -->
          <v-btn
            elevation="2"
            color="orange lighten-1"
            @click="login"
            v-if="!this.auth.currentUser"
          >
            Вход
          </v-btn>
          <v-menu v-else open-on-hover bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="orange" v-bind="attrs" v-on="on" fab small>
                <v-icon dark> mdi-emoticon-happy-outline </v-icon>

                <!-- <div class="icon">
                  <img class="icon-svg" src="@/assets/img/user.svg" alt="">
                </div> -->
              </v-btn>
            </template>

            <v-list>
              <!-- email -->
              <v-list-item>
                <span>{{ this.auth.currentUser.email }}</span>
              </v-list-item>

              <v-list-item class="d-block d-md-none">
                <v-tabs
                  right
                  color="orange"
                  optional
                  vertical
                  :hide-slider="hideSlider"
                >
                  <v-tabs-slider color="orange"></v-tabs-slider>
                  <v-tab :to="{ name: 'public-maps' }"> Публичные карты </v-tab>
                  <v-tab :to="{ name: 'faq' }"> FAQ </v-tab>
                </v-tabs>
              </v-list-item>

              <!-- вход-выход -->
              <v-list-item>
                <v-list-item-title></v-list-item-title>
                <div v-if="!this.auth.currenUser">
                  <!-- show logout when authenticated -->
                  <v-btn
                    class="log-btn"
                    @click="logout()"
                    elevation="2"
                    color="orange lighten-2"
                  >
                    Выход
                    <v-icon class="pl-2">mdi-exit-to-app</v-icon>
                  </v-btn>
                </div>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
      <!-- </div> -->
    </v-container>
  </div>
</template>

<script>
import { onLogout, onLogin } from "../vue-apollo";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

export default {
  name: "header-component",
  data() {
    return {
      auth: getAuth(),
    };
  },

  computed: {
    hideSlider() {
      if (this.$route.path === "/faq" || this.$route.path === "/public-maps") {
        return false;
      } else {
        return true;
      }
    },
  },

  methods: {
    login() {
      this.auth.onAuthStateChanged((firebaseUser) => {
        if (firebaseUser) {
          return firebaseUser
            .getIdToken()
            .then((token) =>
              firebaseUser.getIdTokenResult().then((result) => {
                if (result.claims["https://hasura.io/jwt/claims"]) {
                  return token;
                }
                const endpointRefreshToken =
                  "https://us-central1-mapdev-io.cloudfunctions.net/refreshToken";
                return fetch(
                  `${endpointRefreshToken}?uid=${firebaseUser.uid}`
                ).then((res) => {
                  if (res.status === 200) {
                    return firebaseUser.getIdToken(true);
                  }
                  return res.json().then((e) => {
                    throw e;
                  });
                });
              })
            )
            .catch(console.error);
        }
      });

      const provider = new GoogleAuthProvider();
      signInWithPopup(this.auth, provider)
        .then((result) => {
          const user = result.user;

          if (user) {
            this.auth.currentUser.getIdToken().then((result) => {
              onLogin(this.$apollo, result);
            });

            const endpointAddUser =
              "https://us-central1-mapdev-io.cloudfunctions.net/addUser";
            return fetch(
              `${endpointAddUser}?uid=${user.uid}&email=${user.email}`
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    logout() {
      this.auth.signOut({ returnTo: window.location.origin });
      onLogout(this.$apollo);
    },
  },
};
</script>

<style lang="scss">
.header {
  position: relative;
  z-index: 5;
  box-shadow: 0px 7px 15px -1px rgba(34, 60, 80, 0.2);
  // &__row {
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  // }
}
.log-btn {
  margin-left: 10px;
}
.logo {
  height: 50px;
}
</style>
