import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import App from './App.vue'
import router from './router'
import { createProvider } from './vue-apollo'
import store from './storages/index'
import utils from './mixins/utils'
import { initializeApp } from "firebase/app";
import firebaseConfig from "../auth_config.json"

initializeApp(firebaseConfig);

Vue.mixin(utils)

export const eventBus = new Vue()

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  store,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount('#app')
